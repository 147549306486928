import download from 'downloadjs';
import { DateRange } from '../analytics';
import { apiFetch, HTTPMethod } from '../fetch';
import { apiFetchFormData } from '../fetchFormData';
import { UploadedFile } from '../uploadedFiles';
import { CustomsStatus, DigitalCustomsArticle, DigitalCustomsArticleAndConsolidation, DigitalCustomsConsolidation, DigitalCustomsConsolidationDocument, DigitalCustomsConsolidationError, DigitalCustomsConsolidationsResendResult, DigitalCustomsMaster, DigitalCustomsMasterPreview, DigitalCustomsOrder, HSCodeCorrection, MasterWaybillDocument } from './interfaces';

const queryString = require('query-string');

export const getCompanyDigitalCustomsOrdersByDate = (companyId: string, dateRange: DateRange, isTest: boolean = false) =>
  apiFetch<DigitalCustomsOrder[]>(`/DigitalCustoms/${companyId}/orders?from=${dateRange.from}&to=${dateRange.to}&isTest=${isTest}`);

export const getCompanyDigitalCustomsArticlesByOrderId = (companyId: string, orderId: string) =>
  apiFetch<DigitalCustomsArticle[]>(`/DigitalCustoms/${companyId}/articlesByOrderId?orderId=${orderId}`);

export const getCompanyDigitalCustomsConsolidationsNotSentToMymo = (companyId: string, isTest: boolean = false, dateRange?: DateRange) =>
  apiFetch<DigitalCustomsConsolidation[]>(`/DigitalCustoms/${companyId}/consolidationsNotSentToMymo?isTest=${isTest}&${queryString.stringify(dateRange)}`);

export const getCompanyDigitalCustomsConsolidations = (companyId: string, dateRange: DateRange) =>
  apiFetch<DigitalCustomsConsolidation[]>(`/DigitalCustoms/${companyId}/consolidations?from=${dateRange.from}&to=${dateRange.to}`);

export const getCompanyConsolidationsGroupedByMaster = (companyId: string, dateRange: DateRange) =>
  apiFetch<DigitalCustomsMaster[]>(`/DigitalCustoms/${companyId}/consolidationsGroupedByMaster?fromDate=${dateRange.from}&toDate=${dateRange.to}`);

export const getCompanyConsolidationsByMasterWaybill = (companyId: string, masterwaybill: string, dateRange: DateRange) =>
  apiFetch<DigitalCustomsConsolidation[]>(`/DigitalCustoms/${companyId}/consolidations/masterwaybill/${masterwaybill}?fromDate=${dateRange.from}&toDate=${dateRange.to}`);

export const getCompanyDigitalCustomsUploads = (companyId: string, dateRange: DateRange) =>
  apiFetch<UploadedFile[]>(`/DigitalCustoms/${companyId}/uploads?fromDate=${dateRange.from}&toDate=${dateRange.to}`);

export const getCompanyDigitalCustomsOrdersByConsolidationId = (companyId: string, consolidationId: string) =>
  apiFetch<DigitalCustomsOrder[]>(`/DigitalCustoms/${companyId}/consolidations/${consolidationId}/orders`);

export const getCompanyDigitalCustomsOrdersByMasterWaybill = (companyId: string, masterwaybill: string, dateRange: DateRange, customsStatuses?: CustomsStatus[]) => {
  const customStatusesToFilterOn = customsStatuses ? customsStatuses : Object.values(CustomsStatus);
  const statusQueryString = customStatusesToFilterOn.map((status, i) => `${i === 0 ? '&' : ''}customsStatus=${status}`).join('&');
  return apiFetch<DigitalCustomsOrder[]>(`/DigitalCustoms/${companyId}/consolidations/orders?masterWaybillNumber=${masterwaybill}&fromDate=${dateRange.from}&toDate=${dateRange.to}${statusQueryString}`);
};

export const getCorrectionArticles = (companyId: string, isTest: boolean, dateRange?: DateRange) => {
  const fromDateQuery = dateRange?.from ? `&fromDate=${dateRange.from}` : '';
  const toDateQuery = dateRange?.to ? `&toDate=${dateRange.to}` : '';
  return apiFetch<DigitalCustomsArticle[]>(`/DigitalCustoms/${companyId}/article/corrections?isTest=${isTest}${fromDateQuery}${toDateQuery}`);
};

export const getCompanyDigitalCustomsArticlesByConsolidationId = (companyId: string, consolidationId: string) =>
  apiFetch<DigitalCustomsArticle[]>(`/DigitalCustoms/${companyId}/articlesByConsolidationId?consolidationId=${consolidationId}`);

export const putCompanyDigitalCustomsArticle = (companyId: string, articleId: string, article: DigitalCustomsArticle) =>
  apiFetch<DigitalCustomsArticleAndConsolidation>(`/DigitalCustoms/${companyId}/article?articleId=${articleId}`, article, HTTPMethod.PUT);

export const putCompanyDigitalCustomsOrder = (companyId: string, orderId: string, order: DigitalCustomsOrder) =>
  apiFetch<DigitalCustomsOrder>(`/DigitalCustoms/${companyId}/order?orderId=${orderId}`, order, HTTPMethod.PUT);

export const sendConsolidationToMymo = (companyId: string, consolidationId: string) =>
  apiFetch<DigitalCustomsConsolidation>(`/DigitalCustoms/${companyId}/sendConsolidationToMymo/${consolidationId}`, undefined, HTTPMethod.PUT);

export const getNumberOfCorrectionsForCompany = (companyId: string, isTest: boolean = false) =>
  apiFetch<number>(`/DigitalCustoms/${companyId}/numberOfConsolidationsCorrections?isTest=${isTest}`);

export const getConsolidationErrors = (companyId: string, consolidationId: string) =>
  apiFetch<DigitalCustomsConsolidationError[]>(`/DigitalCustoms/${companyId}/consolidation/${consolidationId}/consolidationErrors`);

export const getConsolidation = (companyId: string, consolidationId: string) =>
  apiFetch<DigitalCustomsConsolidation>(`/DigitalCustoms/${companyId}/consolidation/${consolidationId}`);

export const deleteDigitalCustomsConsolidation = (companyId: string, consolidationId: string): Promise<any> =>
  apiFetch(`/DigitalCustoms/${companyId}/consolidations/${consolidationId}`, undefined, HTTPMethod.DELETE);

export const deleteDigitalCustomsOrder = (companyId: string, orderId: string): Promise<any> =>
  apiFetch(`/DigitalCustoms/${companyId}/orders/${orderId}`, undefined, HTTPMethod.DELETE);

export const editConsolidationNotes = (companyId: string, consolidationId: string, note: string): Promise<DigitalCustomsConsolidation> =>
  apiFetch(`/DigitalCustoms/${companyId}/consolidation/${consolidationId}/consolidationNotes`, note, HTTPMethod.PUT);

export const approveAndSendCorrections = (companyId: string, sendInvalid: boolean, isTest: boolean, dateRange?: DateRange) => {
  const fromDateQuery = dateRange?.from ? `&fromDate=${dateRange.from}` : '';
  const toDateQuery = dateRange?.to ? `&toDate=${dateRange.to}` : '';
  const url = `/DigitalCustoms/${companyId}/ApproveAndSendCorrectionsToBroker?sendInvalid=${sendInvalid}&isTest=${isTest}${fromDateQuery}${toDateQuery}`;
  return apiFetch<number>(url, undefined, HTTPMethod.PUT);
};

export const sendImportToBroker = (companyId: string, importId: string, sendInvalid: boolean) =>
  apiFetch(`/DigitalCustoms/${companyId}/imports/${importId}/sendImportToBroker?sendInvalid=${sendInvalid}`, undefined, HTTPMethod.PUT);

export const getAllDocumentsForConsolidation = (companyId: string, consolidationId: string): Promise<DigitalCustomsConsolidationDocument> =>
  apiFetch(`/DigitalCustoms/${companyId}/${consolidationId}/documents`);

export const deleteConsolidationsDocument = (companyId: string, consolidationId: string, fileId: string) =>
  apiFetch(`/DigitalCustoms/${companyId}/${consolidationId}/documents/${fileId}`, undefined, HTTPMethod.DELETE);

export const uploadConsolidationsDocument = (companyId: string, consolidationId: string, files: any[]) => {

  const formData = new FormData();
  files.forEach(file => formData.append('files', file.originFileObj));

  return apiFetchFormData<MasterWaybillDocument>(
    `/DigitalCustoms/${companyId}/consolidations/${consolidationId}/files`,
    formData,
    HTTPMethod.POST
  )
    .catch(() => Promise.reject(({
      failedToImport: true,
    }) as MasterWaybillDocument))
    .then((digitalCustomsWaybillFile) => {

      if (digitalCustomsWaybillFile.failedToUpload) {
        return Promise.reject(digitalCustomsWaybillFile);
      }
    });
};

export const getCorrectionArticlesInMappings = (companyId: string, articles: DigitalCustomsArticle[]) =>
  apiFetch<DigitalCustomsArticle[]>(`/DigitalCustoms/${companyId}/articlesInMappings`, articles, HTTPMethod.POST);

export const getHsCodesMappingsByIds = (companyId: string, articlesWithMappingIds: string[]) =>
  apiFetch<HSCodeCorrection[]>(`/DigitalCustoms/${companyId}/hsCodeCorrectionsByIds`, articlesWithMappingIds, HTTPMethod.POST);

export const getMasterFileList = (companyId: string, masterwaybill: string) =>
  apiFetch<string[]>(`/DigitalCustoms/${companyId}/attachments?masterWaybillNumber=${masterwaybill}`, undefined, HTTPMethod.GET);

export const getOrdersFileList = (companyId: string, orderId: string) =>
  apiFetch<string[]>(`/DigitalCustoms/${companyId}/attachments/orders?orderId=${orderId}`, undefined, HTTPMethod.GET);

export const getMasterFile = (companyId: string, masterwaybill: string, fileName: string) =>
  apiFetch<Response>(`/DigitalCustoms/${companyId}/attachments?masterWaybillNumber=${masterwaybill}&fileName=${fileName}`, undefined, HTTPMethod.GET)
    .then(async result => {
      const blob = await result.blob();
      return { blob };
    })
    .then(data => download(data.blob, fileName));

export const getOrderFile = (companyId: string, orderId: string, fileName: string) =>
  apiFetch<Response>(`/DigitalCustoms/${companyId}/attachments/orders?orderId=${orderId}&fileName=${fileName}`, undefined, HTTPMethod.GET)
    .then(async result => {
      const blob = await result.blob();
      return { blob };
    })
    .then(data => download(data.blob, fileName));

export const deleteMasterFile = (companyId: string, masterwaybill: string, fileName: string) =>
  apiFetch(`/DigitalCustoms/${companyId}/attachments?masterWaybillNumber=${masterwaybill}&fileName=${fileName}`, undefined, HTTPMethod.DELETE);

export const deleteOrderFile = (companyId: string, orderId: string, fileName: string) =>
  apiFetch(`/DigitalCustoms/${companyId}/attachments/orders?orderId=${orderId}&fileName=${fileName}`, undefined, HTTPMethod.DELETE);

export const uploadMasterFile = (companyId: string, masterwaybill: string, files: any[]) => {
  const formData = new FormData();
  files.forEach(file => formData.append('files', file.originFileObj));
  return apiFetchFormData(`/DigitalCustoms/${companyId}/attachments?masterWaybillNumber=${masterwaybill}`, formData, HTTPMethod.POST);
};
export const uploadOrderFile = (companyId: string, orderId: string, files: any[]) => {
  const formData = new FormData();
  files.forEach(file => formData.append('files', file.originFileObj));
  return apiFetchFormData(`/DigitalCustoms/${companyId}/attachments/orders?orderId=${orderId}`, formData, HTTPMethod.POST);
};
export const resendDigitalCustomConsolidations = (companyId: string, consolidationIds: string[], portOfDestination: string, customsCountry: string) => {
  const params = new URLSearchParams({
    companyId,
    portOfDestination,
    customsCountry,
  });

  consolidationIds.forEach(id => params.append('consolidationIds', id));

  return apiFetch<DigitalCustomsConsolidationsResendResult>(`/v0.1/DigitalCustoms/Resend/Consolidations?${params.toString()}`, undefined, HTTPMethod.POST);
};

export const getCompanyDigitalCustomsMasterPreviews = (companyId: string, dateRange: DateRange) =>
  apiFetch<DigitalCustomsMasterPreview[]>(`/DigitalCustoms/${companyId}/masters?fromDate=${dateRange.from}&toDate=${dateRange.to}`);

export const getCompanyDigitalCustomsDetailedMaster = (companyId: string, masterId: string) =>
  apiFetch<DigitalCustomsMaster>(`/DigitalCustoms/${companyId}/masters/${masterId}`);

export const editMaster = (companyId: string, masterId: string, master: Partial<DigitalCustomsMaster>) =>
  apiFetch<DigitalCustomsMaster>(`/DigitalCustoms/${companyId}/masters/${masterId}`, master, HTTPMethod.PUT);

export const triggerImportOrdersOnHoldNotification = (companyId: string, importId: string) =>
  apiFetch(`/DigitalCustoms/${companyId}/notification/ImportOrdersOnhold?importId=${importId}`, undefined, HTTPMethod.POST);

export const createCommercialInvoiceOnOrderProforma = (companyId: string, orderId: string ) =>
  apiFetch(`/customerReports/${companyId}/${orderId}/pdfFile`, undefined, HTTPMethod.POST);

export const triggerImportNotification = (companyId: string, importId: string) =>
  apiFetch(`/DigitalCustoms/${companyId}/notification/Import?importId=${importId}`, undefined, HTTPMethod.POST);

export const triggerOrderDocumentNotification = (companyId: string, orderId: string, fileName: string) =>
  apiFetch(`/DigitalCustoms/${companyId}/notification/OrderDocument?orderId=${orderId}&fileName=${fileName}`, undefined, HTTPMethod.POST);